<template>
  <div>
    <div class="header">
      <img src="../assets/image/logo.png" alt="" />
    </div>
    <div class="main">
      <div class="main-title">
        <img src="../assets/image/联系我们@2x.png" alt="" />
      </div>
      <div class="intro-div">
        <div class="contact-div">
          <ul>
            <li class="address">
              <p>地址</p>
              <p>福建省泉州市惠安县禹州广场15楼</p>
            </li>
            <li class="mobile">
              <p>电话</p>
              <p>0595-87810165</p>
            </li>
            <!-- <li class="fox">
              <p>传真</p>
              <p>+86 0000-0000-0000</p>
            </li> -->
            <li class="email">
              <p>邮箱</p>
              <p>cc@tulaway.cn</p>
            </li>
          </ul>
        </div>
        <div class="map" id="allmap">
          <baidu-map
            :center="center"
            :zoom="17"
            :scroll-wheel-zoom="true"
            style="height: 360px"
          >
            <bm-marker
              :icon="{
                url: '/坐标.svg',
                size: { width: 56, height: 32 },
              }"
              :position="center"
            >
            </bm-marker
          ></baidu-map>
        </div>
      </div>
    </div>
    <footerComponents ref="footer"></footerComponents>
  </div>
</template>

<script>
import BmMarker from "vue-baidu-map/components/overlays/Marker";
import footerComponents from "@/components/footer";
export default {
  data() {
    return {
      center: { lng: 118.616917, lat: 24.871311 },
    };
  },
  components: { footerComponents, BmMarker },
};
</script>

<style scoped lang="scss">
.header {
  width: 100%;
  height: 500px;
  overflow: hidden;
  position: relative;
  background: url(../assets/image/联系我们底@2x.png) no-repeat;
  background-size: auto 100%;
  background-position: center;
  color: #ffffff;
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -122px;
    margin-top: -70px;
  }
}
.main {
  width: 1200px;
  margin: auto;
  overflow: hidden;
  .main-title {
    text-align: center;
    padding: 80px 0 70px;
  }
  .intro-div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 150px;
    .contact-div {
      display: inline-block;
      ul {
        padding: 0;
        margin: 0;
      }
      li {
        padding: 10px 0 10px 44px;
        background-position: -3px 3px;
        background-repeat: no-repeat;
        p {
          margin: 0 0 14px 0;
          color: #999999;
        }
        p:first-child {
          margin-bottom: 15px;
          font-size: 18px;
          color: #333333;
        }
      }
      .address {
        background-image: url("../assets/image/地址@2x.png");
      }
      .mobile {
        background-image: url("../assets/image/电话@2x.png");
      }
      .fox {
        background-image: url("../assets/image/传真@2x.png");
      }
      .email {
        background-image: url("../assets/image/邮箱@2x.png");
      }
    }
    .map {
      display: inline-block;
      width: 660px;
      height: 360px;
    }
  }
}
</style>